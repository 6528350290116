import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_2/b2_1.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Top 9 reasons to build a digital workplace model | Workativ Blog"
        description="Learn how customers are building digital workplace model in this post pandemic world to provide great employee experience."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Top 9 Reasons To Build a Digital Workplace –
                            Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <h2 class="font-section-sub-header-small">
                          What is a Digital Workplace?
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          ‘Digital workplace’ is the concept that there is a
                          virtual equivalent to the physical workplace, and that
                          this needs to be planned and managed coherently
                          because it is fundamental to people’s productivity,
                          engagement and working health.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This is not a precise definition; just as the notion
                          of ‘workplace’  itself has ill-defined boundaries, so
                          the shape of a digital workplace will vary between
                          organizations. However, at its heart it is about:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Putting people first – the impact on employees is
                            what makes the digital workplace important.
                          </li>
                          <li>
                            A technology layer – advances in technology are
                            driving changes in the digital workplace, and this
                            is what makes it a current issue.{" "}
                          </li>
                          <li>
                            Management and design – proactively developing a
                            digital workplace means addressing it as a whole and
                            coordinating between technology, process and people
                            aspects.{" "}
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          The{" "}
                          <a href="https://workativ.com/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers">
                            digital workplace
                          </a>{" "}
                          provides an organization with five services or
                          capabilities:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Communication and employee engagement</li>
                          <li>Collaboration</li>
                          <li>
                            Finding and sharing of information and knowledge
                          </li>
                          <li>
                            Business applications (process specific tools and
                            employee self-service)
                          </li>
                          <li>
                            Agile working – the ability to be productive any
                            time and place
                          </li>
                        </ul>

                        <p class="font-section-normal-text line-height-2">
                          To work well, these need the be supported by five
                          management activities:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Strategic planning </li>
                          <li>Governance and operational management </li>
                          <li>Proactive support for adoption</li>
                          <li>High quality user experience</li>
                          <li>Robust, secure and flexible technology</li>
                        </ul>

                        <p class="font-section-normal-text line-height-2">
                          An effective digital workplace decouples work from a
                          physical location for much of the time. This freeing
                          up of work has several important implications not just
                          about where people work, but how teams are formed and
                          how people come together to solve ad-hoc problems.
                          Potentially, it can also close the arbitrary gap
                          between white and blue collar workers by giving both
                          equal digital access.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          Challenges of Digital Workplace initiatives
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Let’s take a look at two of the challenges that
                          organizations would face while digital workplace
                          initiatives.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Aligning Business and IT Challenge
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          No project should begin until the goals of both IT and
                          business have been aligned. One of the biggest
                          obstacles companies face is the lack of connectivity
                          between business and technology — the two are now
                          indistinguishable. Businesses need to understand that
                          it’s no longer a question of if businesses are going
                          to use technology to transform for their customers,
                          but rather how and when and how quickly can they do it
                          at scale.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Another issue that plagues businesses is the fear
                          factor of the transformation process. Companies need
                          leadership who will embrace the risk-taking required
                          to transform their operations and adopt an ongoing
                          culture of innovation. While companies are making
                          progress on evolving their customer/employee
                          experience, they are struggling to transform their
                          back-end operations. It’s imperative that
                          organizations gain alignment between IT and the
                          business. It is no longer possible for the CIO and the
                          IT organization to operate separately from other
                          C-Suite leaders.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In addition to alignment at the top, companies also
                          need to include and engage their employees every step
                          of the way in the transformation agenda. Companies
                          will achieve digital leadership if they succeed in
                          balancing the technology with retraining their
                          workforce to realize new skills.{" "}
                        </p>
                        <h5 class="font-section-sub-header-small">
                          2. Technology Integration Challenge
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Culturally, organizations have been built around
                          certain technologies, with specific policies and
                          procedures developed to support them. The integration
                          process for new technologies causes delays as
                          employees face acceptance, training and getting
                          accustomed to new data management techniques. Having
                          multiple data silos may result in redundancy of work
                          and confusion between disparate groups with regards to
                          effective collaboration. Different approaches to data
                          storage make cohesive blending laborious.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          3. People, Process and Culture
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Research shows that employees are not engaged in the
                          culture change journey. This disengagement is often
                          due to generic organizational vision-and-mission
                          statements that make little sense to employees focused
                          on day-to-day objectives. Leadership and management
                          need to translate the broader digital vision into
                          compelling and tangible business outcomes to which
                          employees can relate, for which they feel accountable,
                          and that they can internalize in their current roles.
                          Employees also need to feel like they understand the
                          rationale for change.
                        </p>
                      </div>
                      <div>
                        <h5 class="font-section-sub-header-small">
                          9 Reasons To Build a Digital Workplace
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Here are nine reasons why you should build a digital
                          workplace.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Increase flexibility and improved employee
                          experience
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Digital workplaces will also unify internal
                          communications by enabling employees to stay connected
                          through the channels of their choice (including
                          mobile). This allows for the employee experience to
                          exist outside the typical company firewall. When used
                          correctly, digital workplaces can provide flexibility
                          and personalization. That’s because digital workplaces
                          allow for the integration of different apps and
                          software, which facilitates easier workflows for
                          employees.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Virtual work environments allows borderless
                          communications between employees located in different
                          places across the world, allowing them to remain
                          connected while still maintaining a balance between
                          customer privacy and operational risk. This, in turn,
                          minimizes spending and allows for a better employee
                          experience through enhancing productivity, because
                          employees are equipped with the right tools and
                          information anytime, anywhere.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Optimize costs
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Building a digital workplace allows an organization to
                          nurture collaboration while limiting costs. It
                          bolsters productivity by implementing self-help and
                          self-heal tools (such as conversational ai, workflow
                          automation) that empower users to resolve their own
                          issues. And it eliminates the risk of over reliance on
                          individual employees’ knowledge by implementing
                          deskside support models with standardized processes
                          and procedures. A digital workplace also can improve
                          efficiency by ensuring business units align with each
                          other and by removing barriers that prevent them from
                          fully sharing resources. Because a digital workplace
                          allows easier access to virtual meetings and removes
                          the barriers of time, location, devices and network
                          connections, it provides employees greater work-life
                          balance while increasing productivity and agility for
                          the organization.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          3. Increase Operational Agility
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          In today’s world, organizations face some
                          unprecedented operational challenges. With steadily
                          increasing customer/employee expectations, global
                          competition, and shortened product development
                          lifecycles, organizations responding first faster to
                          change will enjoy the benefits of sales and market
                          share improvement. Operational agility is now central
                          to achieving a competitive edge. Improving operational
                          agility in an organization leads to the reduction of
                          cost and errors, while improving productivity and
                          raising revenue. Agility, and the responsiveness and
                          flexibility it enables, is now seen as mission
                          critical – particularly in a business. A new level of
                          agility is required across the business, as market
                          conditions continue to change. Many enterprises’
                          current operational models are not agile enough to
                          handle huge fluctuations such as the recent COVID19
                          impact. Embracing digital channels over traditional
                          activities is evidently going to have to take
                          priority. Embracing a digital strategy across the
                          entire enterprise can significantly enhance an
                          organization’s ability to change quickly to new market
                          conditions and needs.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          4. Creates a more collaborative culture
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          By providing your employees with all the tools that
                          they need to communicate and coordinate with their
                          team members, you are able to create a collaborative
                          culture in your organization. Smart and reliable
                          communication tools also cut down meeting times and
                          give your staff more room for ideation and innovation.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          How remote workers communicate and collaborate with
                          each other is the most important aspect of any
                          successful remote working strategy and solution. This
                          applies equally to both internal employees and
                          customers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A seamless collaboration experience is the ability of
                          a user to join a virtual meeting from anywhere and any
                          device with one touch and with the best quality of
                          audio and video.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Users should be able to access these meetings from
                          their laptops, smartphones, tablets or room based
                          Video Conferencing units in office, and even take
                          calls on their home landlines or mobile phones with
                          the same ease.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Users should also be able to solve simple IT issues
                          like password resets, account unlocks as well as apply
                          for PTOs and check salary details with ease on the
                          collaboration tool they’re using like Slack or
                          Microsoft Teams via a Conversational AI self-service
                          with IT and HR workflow automation.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This is internal employee Digitalization and is not
                          just a part of a BCP strategy but eventually becomes a
                          way of life which allows organizations to unlock
                          enormous productivity benefits and long term value.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          None of the above are possible if organizations don’t
                          embrace this as a cultural change. Culture is a
                          reflection of the true identity of any organization
                          and it is always driven top down from the CEOs and
                          CXOs. The technology element is a manifestation of the
                          cultural element, however once a certain critical mass
                          is achieved, these 2 elements will start feeding off
                          each other and lead to the evolution of a truly
                          democratized organizational culture. Trust will also
                          play a crucial role, as managers will have to trust
                          their subordinates working from home and the employees
                          will have to live up to that trust.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          5. More time for new ideas
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          By using the right technological tools and automating
                          repetitive tasks through a digital workplace, you are
                          able to make the work-life for your employees easier,
                          which automatically leads to more productivity. That
                          is because employees are able to use their freed up
                          time for problem-solving and coming up with innovative
                          ideas.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          6. Allows employees to work remotely
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          When all the company data and applications can be
                          accessed online from any device and from any location,
                          it gives your employees more opportunity to work
                          remotely. While over 99 percent of the workers want to
                          work remotely at least once their careers, 90% of them
                          actually want to work remotely for the rest of their
                          careers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For organizations, that means, implementing digital
                          workplace strategies will not only increase employee
                          satisfaction but also help retain and attract talent.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          7. Save costs and invest elsewhere
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          By choosing a cost-effective digital workplace that
                          allows your employees to work remotely, you can save
                          costs, which frees up funds for you to invest
                          elsewhere like launching a new service or product to
                          expand your business as well as revenue.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There are lots of ways remote working offers cost
                          savings. Many established businesses have already
                          enjoyed savings due to telecommuting.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          According to Global Workplace Analytics, almost 6 out
                          of 10 employers identify cost savings as a major
                          benefit of telecommuting.{" "}
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Rent and utilities:
                          </span>
                          If most of your team is working from home, you won’t
                          need to pay for larger premises, saving money on rent
                          and utilities.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Cleaning services:
                          </span>
                          With minimal staff onsite, your cleaning services bill
                          is likely to significantly decline.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Food:
                          </span>
                          Whether it is providing a cafeteria service or serving
                          refreshments during meetings, if you have remote
                          employees, you will eliminate this cost.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Taxes:
                          </span>
                          There are three factors that determine a company’s tax
                          burden: payroll, sales and property. Making changes to
                          accommodate remote workers could also impact your tax
                          burden.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          8. Increase productivity
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          In a Digital Workplace where the working hours are
                          flexible and the staff works remotely from different
                          locations, effective collaboration and communication
                          within an organization is really important which is
                          supported by Business Communication Tools. With these,
                          employees can build meaningful connections and have
                          real-time communication with their peers to get the
                          support required to solve problems at work. Hence,
                          organizations with strong Instant messaging
                          applications are usually more productive and have more
                          employee satisfaction than those without. In fact, a
                          report by Software Advice found that around 21% of
                          respondents have seen a “moderate” or “significant”
                          increase in productivity from using IM.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          9. Talent attraction
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Companies using digital workplaces are able to win the
                          war on talent by showcasing their progressive and
                          unique working environment, where top candidates are
                          learning to expect from employers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Businesses that will be successful in the future will
                          be those who break down the barriers between people,
                          workplaces and technologies and empower their
                          employees to be productive and creative wherever they
                          are. IT is a catalyst for new ways of working, but
                          competitive advantage increasingly comes from letting
                          employees use technology in the way they want to. This
                          requires a business culture that puts people first.
                        </p>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        How companies are adopting Digital workplace
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Here are some ways companies are moving towards digital
                        workplace.
                      </p>
                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Conversational AI – IT Helpdesk chatbot and HR
                          Chatbot
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          What if your IT/HR team had a tool to converge every
                          part of their workflow? The reality is, conversational
                          AI, or chatbots, can do much more than automate
                          paper-driven tasks. In fact, it’s one of the fastest
                          growing and easiest means to not only streamlining all
                          your workflows but in helping you meet your IT/HR
                          goals.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Across the board, businesses continue to research the
                          most effective ways to approach automating IT/HR
                          workflows.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Conversational AI doesn’t only provide workflow
                          automation, it also includes a conversational
                          interface. It offers a simple chat interface that
                          requires a low learning curve for anyone on your team.
                          Under the hood, it provides customizable processes and
                          data gathering that can be controlled from one point.
                        </p>
                        <h6 className="font-section-sub-header-small ">
                          2. Workflow automation to transform workplace support
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          To adapt to changes in technology, as well as balance
                          increasing workloads, employees need a surefire way to
                          streamline duties, collaborate, automate, and add
                          transparency to everyday tasks. Workflow automation
                          simplifies the path that documents take, creating
                          transparency while simultaneously automating
                          notifications/escalations and allowing users to
                          segment information by permissions. Smart workflow
                          automation uses AI technology to eliminate
                          bottlenecks, foster collaboration, encourage
                          accountability, and improve processes by creating
                          efficiency and accuracy where there was once chaos.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          One of the biggest benefits of workflow automation is
                          that it improves internal communication. This reduces
                          the rate of employee turnover because one of the
                          biggest reasons employees leave an organization is a
                          lack of communication with management. Because you are
                          automating workflow you also automate communication,
                          because no one has to remember to tell the next person
                          it’s their turn to do something.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workflow automation also reduces errors because it
                          keeps necessary tasks from going unnoticed. Because
                          every person involved in the process is held
                          accountable for their specific role, no one can make
                          the excuse that “no one told me!” Workflow automation
                          also saves companies from costly expenses associated
                          with employee errors and it can cut costs on
                          administrative labor as well.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          You can also seamlessly integrate your existing apps
                          with a workflow automation software like Workativ to
                          streamline and automate repetitive employee requests
                          like unlock account, password resets, user
                          provisioning, and workplace processes like user
                          onboarding, offboarding, etc. This increases the
                          productivity of your IT agents, HR as well as other
                          employees in the organization, letting them focus on
                          the more important tasks that require human
                          intervention.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Proliferation of Slack and Microsoft Teams combined
                          with Conversational AI
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          One of the most problematic areas in IT/HR, as well as
                          in any organization, is how systems work together for
                          cohesion. Per one report, most businesses use, on
                          average have 30 different systems.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          IT software solutions, third-party or contracted HR
                          groups and roles are one solution to help your team
                          track onboarding, new-hire orientation, payroll,
                          taxes, employee benefits and expense tracking.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          But conversational AI can play a supportive role in
                          connecting these facets so that this information is
                          easy to access, all in one space, by a simple request.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          With a Conversational AI like Workativ, there’s a new
                          way that employees can use Microsoft Teams/Slack by
                          bringing AI into conversations to interact with
                          services such as productivity tools, user management,
                          access management, and more. Employees would be able
                          to collaborate with teams as well as engage with an AI
                          chatbot for IT and HR support. Using Workativ, your
                          employees won’t have to wait in queue for the help
                          desk and instead can autonomously complete their
                          requests and resolve workplace issues on their own.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can effortlessly send approvals and
                          notifications or resolve issues right from Slack with
                          Workativ and Slack integration. Workativ’s chatbot
                          platform integrates with popular applications like
                          Zendesk, Jira, ServiceNow, Azure, Auth0, Box, and can
                          connect them all to enable teams to create automated
                          workflows to reduce the amount of manual work required
                          on routine tasks and repetitive issues.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Workativ’s no-code Conversational AI + workplace
                          Automation platform allows you to integrate with your
                          ITSM and HRMS tools and create the desired workflows
                          to give your employees the IT/HR support they need,
                          instantly.   Try Workativ today by signing up for a
                          FREE trial at workativ.com
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free MS Teams Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
